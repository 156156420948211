<template>
   <div class="row">
		<div class="col-12" v-if="telaAjuda[$route.path.substring(1, $route.path.lastIndexOf('/'))]">
         <div class="alert alert-secondary alert-dismissible fade show mb-2" role="alert">
            <strong>{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/") : '' }}</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
            <span>Busque pelos comprovantes de entradas e saídas gerados manualmente ou automaticamente pelo sistema. Clique em 'Visualizar' para ter a opção de cancelar a operação.</span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="$store.dispatch('toggleTelaAjuda', $route.path.substring(1, $route.path.lastIndexOf('/')))"></button>
         </div>
      </div>

      <div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
            <i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-0">
               <div class="row">
                  <div class="col-sm-5 col-lg-6 mb-3">
                     <label><i class="far fa-store color-theme font-11 me-1"></i> Loja</label>
                     <v-select name="Desktop" :options="dadosUsuario.usuarioLojas" v-model="pesquisa.loja" label="nomeFantasia" :reduce="loja => loja.id" placeholder="Todas as lojas">
                        <template #option="option">{{ option.nomeFantasia }}<small class="mx-2"><i class="fal fa-grip-lines-vertical"></i></small><i class="far fa-tag font-11 me-1"></i> {{ option.numeroLoja }}</template>
                        <template v-slot:no-options>
									<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Solicite permissão ou utilize a tela "Lojas" para adicionar</small>
								</template>
                     </v-select>
                     <select name="Mobile" class="form-control" v-model="pesquisa.loja">
                        <option v-for="(option, index) in dadosUsuario.usuarioLojas" :key="index" :value="option.id">{{ option.nomeFantasia }}&nbsp;&nbsp;|&nbsp;&nbsp;nº {{ option.numeroLoja }}</option>
                     </select>
                  </div>
                  <div class="col-9 col-sm-5 col-lg-5 mb-3">
                     <label><i class="far fa-calendar color-theme font-11 me-1"></i> Data</label>
                     <datePicker format="DD/MM/YYYY" :range="true" :clearable="false" :editable="false" v-model="pesquisa.data"></datePicker>
                  </div>
                  <div class="col-3 col-sm-2 col-lg-1 mb-3 align-self-end text-center">
                     <button type="button" class="btn btn-primary w-100" @click="buscarComprovantes"><i class="fas fa-search font-13"></i></button>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-12" v-if="pesquisa.resultado.length > 0">
			<div class="card mb-0">
				<div class="card-body p-12">
					<div class="row">
						<div class="col mb-2 align-self-center">
							<div class="limitador-1 mb-1"><i class="fas fa-angle-right color-theme me-1"></i> {{ pesquisa.resultado.length }} resultado(s)</div>
						</div>
						<div class="w-max-content mb-2">
							<a href="javascript:;" class="badge badge-default bg-theme mb-1" @click="buscarComprovantes(null, true)"><i class="far fa-share font-10 me-1"></i> Exportar</a>
						</div>
					</div>

					<div class="cards-150 cards-sm-200">
						<comprovante v-for="(cupom, index) in pesquisa.resultado" :key="index" :index="index" :cupom="cupom" :preview="true" @visualizar="verComprovante($event)" />
					</div>

					<div class="w-100 mt-3 mb-2 text-center" v-if="pesquisa.resultado.length % 30 == 0">
						<button type="button" class="btn btn-secondary btn-md font-12" @click="buscarComprovantes(null, false)">Ver mais</button>
					</div>
				</div>
			</div>
      </div>

      <modalComprovante :modalAnterior="null" :tipo="comprovante.tipo" :comprovante="comprovante.dados" @cancelar="cancelarNota($event)" />
   </div>
</template>

<script>

import $ from 'jquery'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import comprovante from '@/components/modelos/Comprovante.vue'
import modalComprovante from '@/components/modelos/ModalComprovante.vue'

export default {
	name: 'Notas_entradas_e_saidas',
	data: function () {
		return {
			pesquisa: {'loja': null, 'pagina': 0, 'data': [new Date(), new Date()], 'resultado': []},
			comprovante: {'tipo': null, 'dados': null},
			listaExportacao: []
		}
	},
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
			telaAjuda: state => state.telaAjuda,
         urlRest: state => state.urlRest,
			tabs: state => state.tabs
      })
   },
   watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function (value) {
         Object.assign(this.$data, this.tabs[value].data)
			this.buscarLojas()
      },
      'pesquisa.data' : function (value) {
         for (let i = 0; i < value.length; i++) {
            if (!(value[i] instanceof Date)) {
               value[i] = new Date(value[i])
            }
         }
      }
	},
   components: {
		comprovante, modalComprovante
	},
   methods: {
		verComprovante : function (comprovante) {
			this.comprovante = JSON.parse(JSON.stringify(comprovante))
			$("#modalVerComprovante").modal('show')
		},
      buscarComprovantes : function (pesquisa, exportar) {
			if (Math.round((this.pesquisa.data[1].getTime() - this.pesquisa.data[0].getTime()) / (24 * 60 * 60 * 1000)) >= 90) {
				this.$toast.fire({
					icon: 'error',
					title: 'Período da busca maior que 90 dias.'
				});
	
				return
			}

			this.$store.dispatch('alternarTelaCarregamento', true)
			this.pesquisa.resultado = pesquisa != null ? [] : this.pesquisa.resultado
			Object.assign(this.$data.listaExportacao, this.$options.data.apply(this).listaExportacao)

			this.$axios({
				method: 'post',
				url: this.urlRest +'resultados/searchComprovantesProduto',
				headers: {'Content-Type': 'application/json'},
				data: {
					'loja': this.pesquisa.loja, 
					'data': this.pesquisa.data, 
					'pagina': exportar ? -1 : (pesquisa != null ? 0 : this.pesquisa.pagina)
				}
			}).then(response => {
				if (exportar) {
               this.listaExportacao = response.data
            } else {
               this.pesquisa.pagina++;
               this.pesquisa.resultado.push(... response.data)
            }
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)

				if (exportar) {
               this.$store.dispatch('exportarExcel', {
                  'nome': 'Comprovantes',
                  'resultado': this.listaExportacao, 
                  'campos': ['id', 'motivo', 'nomeLoja', 'data', 'valor'], 
                  'lista': null
               })
            }
			});
		},
		cancelarNota : function (idNota) {
			$("#modalVerComprovante").modal('hide')
			
         Swal.fire({
            icon: 'warning',
            title: 'Tem certeza?',
            text: 'A nota selecionada será cancelada!',
            showCancelButton: true,
				input: 'textarea',
				inputPlaceholder: 'Informe uma justificativa. (Mínimo de 15 caracteres)',
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`,
            inputValidator: (value) => {
               if (String(value).trim().length < 15) {
                  return 'Justificativa inválida!'
               }
            }
         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest +'produtos/cancelarEntradaSaida',
                  headers: {'Content-Type': 'application/json'},
                  data: {'idNota': idNota, 'justificativa': String(result.value).trim()}

               }).then(() => {
						this.pesquisa.resultado.splice(this.pesquisa.resultado.findIndex(n => n.id == idNota), 1)

                  Swal.fire({
                     icon: 'success',
                     title: 'Nota cancelada!'
                  });
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        });
                     }
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         })
      }
   },
	mounted() {
		Object.assign(this.$data, this.tabs[this.$route.params.idTab].data)
	}
}

</script>